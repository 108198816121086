<template>
             <div class="mt-8 px-1">
                <span class="text-gray-600 font-semibold text-md">Set amount</span>
                <!-- <Divider>Set amount </Divider> -->
                <div class="pt-0 pb-2 mt-0 rounded-xl">
                  <div class="">
                    <!-- <label for="email" class="block text-md font-medium text-green-600">Amount:</label> -->
                    <div class="relative rounded-xl py-2">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span v-if="selectedDepositCurrency=='USD'" class="text-gray-600 ">
                        $
                      </span>
                      <span v-if="selectedDepositCurrency=='EUR'" class="text-gray-600 ">
                        €
                      </span>
                    </div>
                    <input v-model="withdrawAmount" maxlength="6" type="number" name="price" id="price" class="py-2 bg-gray-100 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300 " placeholder="100.00" />
              
                  </div>
                   <!-- Error Message -->
                    <div class="input-errors" v-for="(error, index) of v$.withdrawAmount.$errors" :key="index">
                      <div class="error-msg text-red-500 text-xs ml-1">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
                <!-- <Divider class="mt-4">Select destination </Divider> -->
                <div class="mt-2 mb-1 flex justify-between">
                  <span class="text-gray-600 font-semibold text-md">Select destination</span>
                      <span v-if="bankList.length>0" @click="addAccountModal=true" class="text-gray-400 font-semibold text-md">+ Add account</span>
                </div>
                
                  <div @click="addAccountModal=true" v-if="bankList.length==0" class="py-2 w-full border-2 border-dashed border-gray-200 rounded-lg text-center">
                    <span class="text-gray-300 text-xl">+ Add account</span>
                  </div>
                 <RadioGroup v-model="selectedDestination">
                    <RadioGroupLabel class="sr-only">
                      Deposit destination
                    </RadioGroupLabel>
                    <div class="bg-white rounded-xl -space-y-px">
                      <RadioGroupOption as="template" v-for="(source, sourceIdx) in bankList" :key="source.bank" :value="source" v-slot="{ checked, active }">
                        <div :class="[sourceIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', sourceIdx === bankList.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-green-50 border-green-200 z-10' : 'border-gray-200', 'relative border px-4 py-2 flex cursor-pointer focus:outline-none']">
                          <span :class="[checked ? 'bg-green-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-green-500' : '', 'h-5 w-5 mt-2.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                            <span class="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div class="ml-3 flex flex-col">
                            <RadioGroupLabel as="span" :class="[checked ? 'text-green-900' : 'text-gray-900', 'block text-sm font-medium']">
                              {{ source.bank }}
                            </RadioGroupLabel>
                            <RadioGroupDescription as="span" :class="[checked ? 'text-green-700' : 'text-gray-600', 'block text-sm']">
                              {{source.type}}: {{ bankNumber(source.number) }}
                            </RadioGroupDescription>
                          </div>
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
                  <!-- <Divider class="mt-4">Confirm</Divider> -->
                  <div class="mt-8 mb-2 flex justify-center">
                    <savvy-button :disabled="canWithdraw" @click="submitForm()">
                    <!-- <ValutIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" :fill="'#fff'"/> -->
                    Withdraw now
                    </savvy-button>
                    <!-- modal -->
                <confirm-modal :show="confirmWithdraw" @confirm="withdraw()" @canceled="confirmWithdraw=false" 
                    :confirmtitle="'Confirm Withdrawal'" 
                    :confirmtext="'You\'re withdrawing €'+this.numberWithCommas(withdrawValue)+' from account ending: '+bankNumber(destNumber)+'.'">
                    <ArrowIcon class="w-6 h-6  rounded-full text-gray-500" :fill="'#0a0a0a'" style="transform: rotate(225deg)"/>  
                </confirm-modal> 
                <account-modal :show="addAccountModal" @canceled="addAccountModal=false"  @confirm="addAccountModal=false"/>
                  </div>
        </div>
</template>
<script>
import ConfirmModal from '@/components/confirmModal.vue'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import ArrowIcon from '@/assets/icons/arrow.vue'
import SavvyButton from '@/components/button.vue'
import AccountModal from '@/components/addAccountModal.vue'
import { required, maxValue } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default{
    components:{
        ConfirmModal,
        RadioGroup,
        RadioGroupDescription,
        RadioGroupLabel,
        RadioGroupOption,
        ArrowIcon,
        SavvyButton,
        AccountModal
    },
    data(){
    return{
      selectedDepositCurrency:'EUR',
      selectedDestination:null,
      withdrawAmount:null,
      confirmWithdraw:false,
      addAccountModal:false
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      withdrawAmount: {
        required,
         maxValue: maxValue(this.maxWithdraw)
      }
    }  
  },

  methods:{
    async submitForm(){
      const isFormCorrect = await this.v$.withdrawAmount.$validate()
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (isFormCorrect){
        this.openWithdrawModal()
      }
    },

    changeTab(name){
      this.currentTab=name
    },
    withdraw(){
      this.$store.commit('withdraw', {
        withdraw:this.withdrawAmount,
        tickets: Math.floor(this.withdrawAmount)
      })
      this.$store.commit('addItemToActionList', {
        type:'withdraw',
        value: this.withdrawAmount
      })
      this.withdrawAmount=null
      this.confirmWithdraw=false
      this.$router.push('account')
    },
    openWithdrawModal(){
      if(this.canWithdraw) this.confirmWithdraw=true
    },
  },
  computed:{
    bankList(){
      return this.$store.state.bankList
    },
    canWithdraw(){
      if(this.withdrawAmount>0 && this.selectedDestination!=null) return true
      return false
    },
    destNumber(){
        if(this.selectedDestination!=null && this.selectedDestination.number!=undefined) return this.selectedDestination.number
        return ''
    },
    withdrawValue(){
      if(this.withdrawAmount!=null){
        return parseFloat(this.withdrawAmount).toFixed(2)
      }
      else return 0
    },
    maxWithdraw(){
      return parseFloat(this.$store.state.userBalance.balance).toFixed(2)
    }
  }
}
</script>
