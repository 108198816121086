<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full text-white bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                <plusIcon class=""/>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  Add new account
                </DialogTitle>
                <div class="mt-8">

              <div clas="w-full flex justify-center">
                <nav class="flex justify-center space-x-4 bg-gray-100 rounded-lg" aria-label="Tabs">
                    <div @click="changeTab(tab.name)" v-for="tab in tabs" :key="tab.name"  :class="[currentTab==tab.name ? 'bg-green-500 text-white rounded-lg' : 'text-gray-600 hover:text-gray-700 rounded-lg', 'py-2 w-full font-medium text-center text-md rounded-lg']" :aria-current="tab.current ? 'page' : undefined">
                      {{ tab.name }}
                    </div>
                </nav>
              </div>


              <div v-if="currentTab=='Card'" class="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">

                <div class="col-span-4">
                  <label for="bank-name" class="block text-left text-sm font-medium text-gray-700">Bank</label>
                  <div class="mt-1">
                    <input v-model="form.bank" type="text" id="bank" name="bank" autocomplete="bank-name" class="bg-gray-100 py-1 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300 sm:text-sm" />
                  </div>
                  <!-- Error Message -->
                  <div class="input-errors" v-for="(error, index) of v$.form.bank.$errors" :key="index">
                    <div class="error-msg text-red-500 text-left text-xs ml-1">{{ error.$message }}</div>
                  </div>
                </div>
                
                <div class="col-span-4">
                  <label for="name-on-card" class="block text-left text-sm font-medium text-gray-700">Name on card</label>
                  <div class="mt-1">
                    <input v-model="form.name" type="text" id="name-on-card" name="name-on-card" autocomplete="cc-name" class="bg-gray-100 py-1 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300 sm:text-sm" />
                  </div>
                  <!-- Error Message -->
                  <div class="input-errors" v-for="(error, index) of v$.form.name.$errors" :key="index">
                    <div class="error-msg text-red-500 text-left text-xs ml-1">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="col-span-4">
                  <label for="card-number" class="block text-left text-sm font-medium text-gray-700">Card number</label>
                  <div class="mt-1">
                    <input @input="addSpaceToCard($event)" maxlength="19" placeholder="xxxx xxxx xxxx xxxx" v-model="form.number" id="card-number" name="card-number" autocomplete="cc-number" class="bg-gray-100 py-1 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300 sm:text-sm" />
                  </div>
                  <!-- Error Message -->
                  <div class="input-errors" v-for="(error, index) of v$.form.number.$errors" :key="index">
                    <div class="error-msg text-red-500 text-left text-xs ml-1">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="col-span-2">
                  <label for="expiration-date" class="block text-left text-sm font-medium text-gray-700">Expiration date</label>
                  <div class="mt-1">
                    <input type="text" @input="addSlashToDate($event)" v-model="form.expdate" maxlength="5" placeholder="(MM/YY)" name="expiration-date" id="expiration-date" autocomplete="cc-exp" class="bg-gray-100 py-1 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300 sm:text-sm" />
                  </div>
                  <!-- Error Message -->
                  <div class="input-errors" v-for="(error, index) of v$.form.expdate.$errors" :key="index">
                    <div class="error-msg text-red-500 text-left text-xs ml-1">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="col-span-1">
                  <label for="cvc" class="block text-left text-sm font-medium text-gray-700">CVC/CVV</label>
                  <div class="mt-1">
                    <input v-model="form.cvc" type="text" placeholder="xxx" maxlength="3" name="cvc" id="cvc" autocomplete="csc" class="bg-gray-100 py-1 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-2 pr-2  border-gray-300 sm:text-sm" />
                  </div>
                  <!-- Error Message -->
                  <div class="input-errors" v-for="(error, index) of v$.form.cvc.$errors" :key="index">
                    <div class="error-msg text-red-500 text-left text-xs ml-1">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div v-if="currentTab=='Account'" class="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">

                <div class="col-span-4">
                  <label for="bank-name" class="block text-left text-sm font-medium text-gray-700">Bank</label>
                  <div class="mt-1">
                    <input v-model="form2.bank" type="text" id="bank" name="bank" autocomplete="bank-name" class="bg-gray-100 py-1 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300 sm:text-sm" />
                  </div>
                    <!-- Error Message -->
                  <div class="input-errors" v-for="(error, index) of v$.form2.bank.$errors" :key="index">
                    <div class="error-msg text-red-500 text-left text-xs ml-1">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="col-span-4">
                  <label for="name-on-account" class="block text-left text-sm font-medium text-gray-700">Name on account</label>
                  <div class="mt-1">
                    <input v-model="form2.name" type="text" name="name" id="name-on-account"  class="bg-gray-100 py-1 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300 sm:text-sm" />
                  </div>
                      <!-- Error Message -->
                  <div class="input-errors" v-for="(error, index) of v$.form2.name.$errors" :key="index">
                    <div class="error-msg text-red-500 text-left text-xs ml-1">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="col-span-4">
                  <label for="account-number" class="block text-left text-sm font-medium text-gray-700">Account Number</label>
                  <div class="mt-1">
                    <input @input="addSpaceToAccount($event)" placeholder="xxxx xxxx" maxlength="9" v-model="form2.number" id="account-number" name="account-number"  class="bg-gray-100 py-1 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300 sm:text-sm" />
                  </div>
                      <!-- Error Message -->
                  <div class="input-errors" v-for="(error, index) of v$.form2.number.$errors" :key="index">
                    <div class="error-msg text-red-500 text-left text-xs ml-1">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="col-span-3">
                  <label for="sort-code" class="block text-left text-sm font-medium text-gray-700">Sort Code</label>
                  <div class="mt-1">
                    <input @input="addDashToSortcode($event)" v-model="form2.sortcode" maxlength="8" type="text" id="sort_code" name="sort_code" autocomplete="off" placeholder="xx xx xx" class="bg-gray-100 py-1 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300 sm:text-sm" />
                  </div>
                      <!-- Error Message -->
                  <div class="input-errors" v-for="(error, index) of v$.form2.sortcode.$errors" :key="index">
                    <div class="error-msg text-red-500 text-left text-xs ml-1">{{ error.$message }}</div>
                  </div>
                </div>

           
              </div>
     

                  
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
              <savvy-button v-if="currentTab=='Card'" :disabled="!v$.form.$invalid" type="button" @click="submitCardForm()">
                Confirm
              </savvy-button>
              <savvy-button v-if="currentTab=='Account'" :disabled="!v$.form2.$invalid" type="button" @click="submitAccountForm()">
                Confirm
              </savvy-button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" @click="cancelAction()" ref="cancelButtonRef">
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import plusIcon from '@/assets/icons/plus.vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import useVuelidate from '@vuelidate/core'
import { required, maxLength, minLength, numeric } from '@vuelidate/validators'
import SavvyButton from '@/components/button.vue'

const tabs = [
  { name: 'Account', href: '#', current: true },
  { name: 'Card', href: '#', current: false },
]

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    plusIcon,
    SavvyButton
  },
  setup() {
    return {
      tabs,
      v$: useVuelidate()
    }
  },
  data(){
      return{
          currentTab:'Account',
          open:false,
          form:{
            bank:null,
            name:null,
            number:null,
            type:'Visa',
            cvc:null,
            expdate:null
          },
          form2:{
            bank:null,
            name:null,
            number:null,
            type:'Account',
            sortcode:null
          }
      }
  },
  validations() {
    return {
      form: {
        name:{
           required,
           min:minLength(2),
           max:maxLength(30)
        },
        bank:{
           required,
           min:minLength(2),
           max:maxLength(30)
        },
        number:{
           required,
           min:minLength(19),
           max:maxLength(19),
        },
        cvc:{
          numeric,
           required,
           min:minLength(3),
           max:maxLength(3)
        },
        expdate:{
           required,
           min:minLength(5),
           max:maxLength(5)
        }
      },
      form2: {
        name:{
           required,
           min:minLength(2),
           max:maxLength(30)
        },
         bank:{
           required,
           min:minLength(2),
           max:maxLength(30)
        },
        number:{
           required,
           min:minLength(9),
           max:maxLength(9)
        },
        sortcode:{
           required,
           min:minLength(8),
           max:maxLength(8)
        },
      }
    }
  },

  methods:{
    async submitCardForm(){
      const isFormCorrect = await this.v$.form.$validate()
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (isFormCorrect){
        this.confirmAction()
      }
    },
    async submitAccountForm(){
      const isFormCorrect = await this.v$.form2.$validate()
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (isFormCorrect){
        this.confirmAction()
      }
    },
      async confirmAction(){
        if(this.currentTab=='Account'){
          this.$store.dispatch('newBankList', {
            account:Object.assign({},this.form2)
          }).then(() => {
            this.resetForms()
          })
          
        }
        else{
          this.$store.dispatch('newBankList', {
            account:Object.assign({},this.form)
          }).then(() => {
            this.resetForms()
          })
       
        }
        //if success
        this.open=false
        this.$emit('confirm')
      },
      cancelAction(){
          this.$emit('canceled')
          this.resetForms()
          this.open=false
      },
      changeTab(name){
       this.v$.$reset()
        this.currentTab=name
        this.form.type=name
        // this.$store.commit('setDepositTab', name)
      },
      resetForms(){
          this.v$.$reset()
            this.form.bank=null,
            this.form.expdate=null,
            this.form.cvc=null,
            this.form.name=null,
            this.form.number=null,
            this.form.type='Visa'
            this.form2.bank=null,
            this.form2.sortcode=null,
            this.form2.name=null,
            this.form2.number=null,
            this.form2.type='Account'
      },
      addDashToSortcode(event){
        console.log(event)
        if(event.inputType!='deleteContentBackward'){
          if(this.form2.sortcode.length==2 ){
            this.form2.sortcode=this.form2.sortcode+" "
          }
          if(this.form2.sortcode.length==5){
            this.form2.sortcode=this.form2.sortcode+" "
          }
        }
      },
      addSpaceToAccount(event){
        if(event.inputType!='deleteContentBackward'){
          if(this.form2.number.length==4 ){
            this.form2.number=this.form2.number+" "
          }
        }
      },
      addSpaceToCard(event){
        if(event.inputType!='deleteContentBackward'){
          if(this.form.number.length==4 ){
            this.form.number=this.form.number+" "
          }
          if(this.form.number.length==9 ){
            this.form.number=this.form.number+" "
          }
          if(this.form.number.length==14 ){
            this.form.number=this.form.number+" "
          }
        }
      },
      addSlashToDate(event){
        if(event.inputType!='deleteContentBackward'){
          if(this.form.expdate.length==2 ){
            this.form.expdate=this.form.expdate+"/"
          }
        }
      },
  }
}
</script>