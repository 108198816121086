<template>
  <div class="px-4 pt-2 sm:px-6">
        <div clas="w-full flex justify-center">
          <nav class="flex justify-center space-x-4 bg-gray-100 rounded-lg" aria-label="Tabs">
              <div @click="changeTab(tab.name)" v-for="tab in tabs" :key="tab.name"  :class="[$store.state.depositTab==tab.name ? 'bg-green-500 text-white rounded-lg' : 'text-gray-600 hover:text-gray-700 rounded-lg', 'py-2 w-full font-medium text-center text-md rounded-lg']" :aria-current="tab.current ? 'page' : undefined">
                {{ tab.name }}
              </div>
          </nav>
        </div>
        <!-- <transition-group name="fade" mode="out-in"> -->
          <div v-if="$store.state.depositTab=='Deposit'">
              <deposit-form/>
          </div>

          <div v-if="$store.state.depositTab=='Withdraw'">
                <withdraw-form/>
          </div>
        <!-- </transition-group> -->
  </div>
</template>

<script>
import WithdrawForm from '@/components/WithdrawForm.vue'
import DepositForm from '@/components/DepositForm.vue'

const tabs = [
  { name: 'Deposit', href: '#', current: false },
  { name: 'Withdraw', href: '#', current: true },
]

export default  {
  components:{
    DepositForm,
    WithdrawForm

  },
  setup() {
    return {
      tabs,
    }
  },
  data(){
    return{
      currentTab:'Deposit',
    }
  },
  methods:{
    changeTab(name){
      this.currentTab=name
      this.$store.commit('setDepositTab', name)
    },
  },
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>