<template>
    <div class="mt-8 px-1">
                <span class="text-gray-600 font-semibold text-md">Set amount</span>
                <div class="border-gray-200 pb-2  rounded-lg">
                  <div>
                    <!-- <label for="email" class="block text-md font-medium text-green-600">Amount:</label> -->
                    <div class="relative rounded-xl py-2">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span v-if="selectedDepositCurrency=='USD'" class="text-gray-600 ">
                        $
                      </span>
                      <span v-if="selectedDepositCurrency=='EUR'" class="text-gray-600 ">
                        €
                      </span>
                    </div>
                    <input v-model="depositAmount" type="number" name="price" id="price" maxlength="6" class="py-2 bg-gray-100 rounded-lg focus:ring-green-500 focus:border-green-500 block w-full pl-7 pr-12  border-gray-300 " placeholder="100.00" />

                  </div>
                     <!-- Error Message -->
                    <div class="input-errors" v-for="(error, index) of v$.depositAmount.$errors" :key="index">
                      <div class="error-msg text-red-500 text-xs ml-1">{{ error.$message }}</div>
                    </div>
                  </div>
                  <div class="mt-0">
                    <div class="flex justify-between items-center">
                      <div class="min-w-0 flex-1 text-md">
                        <label for="ferquency" class="font-normal text-gray-400">Make this a regular deposit?</label>
                      </div>
                      <div class="ml-3 flex items-center h-5">
                        <Switch v-model="setFrequency" :class="[setFrequency ? 'bg-green-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500']">
                          <span class="sr-only">Use source</span>
                          <span :class="[setFrequency ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                            <span :class="[setFrequency ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                              <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </span>
                            <span :class="[setFrequency ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                              <svg class="h-3 w-3 text-green-600" fill="currentColor" viewBox="0 0 12 12">
                                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                              </svg>
                            </span>
                          </span>
                        </Switch>
                      </div>
                    </div>
                    <!-- <label for="email" class="block text-sm font-medium text-green-600">Set Frequency:</label> -->
                    <div v-if="setFrequency" class="mt-2">
                      <span class="text-gray-600 text-xs font-normal">How often do you want to save?</span>
                      <select  id="location" name="location" class="bg-gray-100 rounded-lg block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none focus:ring-green-500 focus:border-green-500 ">
                        <option>Daily</option>
                        <option>Weekly</option>
                        <option>Every 2 weeks</option>
                        <option>Monthly</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- <Divider class="mt-4">Select source </Divider> -->
           
                <div class="mt-4 mb-1 flex justify-between">
                  <span class="text-gray-600 font-semibold text-md">Select source</span>
                  <span v-if="bankList.length>0" @click="addAccountModal=true" class="text-gray-400 font-semibold text-md">+ Add account</span>
                </div>
                
                  <div @click="addAccountModal=true" v-if="bankList.length==0" class="py-2 w-full border-2 border-dashed border-gray-200 rounded-lg text-center">
                    <span class="text-gray-300 text-xl">+ Add account</span>
                  </div>
                 <RadioGroup v-model="selectedSource">
                    <RadioGroupLabel class="sr-only">
                      Deposit source
                    </RadioGroupLabel>
                    <div class="bg-white rounded-xl -space-y-px">
                      <RadioGroupOption as="template" v-for="(source, sourceIdx) in bankList" :key="source.bank" :value="source" v-slot="{ checked, active }">
                        <div :class="[sourceIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', sourceIdx === bankList.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-green-50 border-green-200 z-10' : 'border-gray-200', 'relative border py-2 px-4 flex cursor-pointer focus:outline-none']">
                          <span :class="[checked ? 'bg-green-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-green-500' : '', 'h-5 w-5 mt-2.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                            <span class="rounded-full bg-white w-1.5 h-1.5" />
                          </span>
                          <div class="ml-3 flex flex-col">
                            <RadioGroupLabel as="span" :class="[checked ? 'text-green-900' : 'text-gray-900', 'block text-sm font-medium']">
                              {{ source.bank }}
                            </RadioGroupLabel>
                            <RadioGroupDescription as="span" :class="[checked ? 'text-green-700' : 'text-gray-600', 'block text-sm']">
                              {{source.type}}: {{ bankNumber(source.number) }}
                            </RadioGroupDescription>
                          </div>
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
    
                  <div class="mt-8 mb-2 flex justify-center">
                    <savvy-button :disabled="canDeposit" @click="submitForm()">
                    Deposit now
                    </savvy-button>
                  </div>
                  <confirm-modal :show="confirmDeposit" @confirm="addDeposit()" @canceled="confirmDeposit=false" 
                  :confirmtitle="'Confirm Deposit'" 
                  :confirmtext="'You\'re depositing €'+this.numberWithCommas(depositValue)+' to account ending: '+bankNumber(sourceNumber)+'.'">
                    <ArrowIcon class="w-6 h-6  rounded-full text-gray-500" :fill="'#0a0a0a'" style="transform: rotate(45deg)"/>  
                  </confirm-modal> 
                  <account-modal :show="addAccountModal" @canceled="addAccountModal=false"  @confirm="addAccountModal=false"/>
              </div>


</template>

<script>

import { Switch } from '@headlessui/vue'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import ArrowIcon from '@/assets/icons/arrow.vue'
import ConfirmModal from '@/components/confirmModal.vue'
import AccountModal from '@/components/addAccountModal.vue'
import SavvyButton from '@/components/button.vue'
import { required, maxValue } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'

export default  {
  components:{
    Switch,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    ArrowIcon,
    ConfirmModal,
    SavvyButton,
    AccountModal
  },

  data(){
    return{
      selectedDepositCurrency:'EUR',
      setFrequency:false,
      selectedSource:null,
      depositAmount:null,
      confirmDeposit:false,
      addAccountModal:false
    }
  },

  setup () {
    return { v$: useVuelidate() }
  },

  validations() {
    return {
      depositAmount: {
        required,
        maxValue: maxValue(this.maxDeposit)
      }
    }  
  },

  methods:{
    async submitForm(){
      const isFormCorrect = await this.v$.depositAmount.$validate()
      // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
      if (isFormCorrect){
        this.openDepositModal()
      }
    },

    addDeposit(){
      this.$store.commit('addDeposit', {
        deposit:this.depositAmount,
        tickets: Math.floor(this.depositAmount)
      })
      this.$store.commit('addItemToActionList', {
        type:'deposit',
        value: this.depositAmount
      })
      this.depositAmount=null
      this.confirmDeposit=false
      this.$router.push('account')
    },

    openDepositModal(){
      if(this.canDeposit) this.confirmDeposit=true
    }

  },
  computed:{
    bankList(){
      return this.$store.state.bankList
    },
    canDeposit(){
      if(this.depositAmount>0 && this.selectedSource!=null) return true
      return false
    },
    sourceNumber(){
        if(this.selectedSource!=null && this.selectedSource.number!=undefined) return this.selectedSource.number
        return ''
    },
    depositValue(){
      if(this.depositAmount!=null){
        return parseFloat(this.depositAmount).toFixed(2)
      }
      else return 0
    },
    maxDeposit(){
      return 100000 - parseFloat(this.$store.state.userBalance.balance).toFixed(2)
    }
  }
}
</script>